.stucco {
  background: $night;
  padding: 90px 0;

  @media $handheld {
    padding: 30px 0;
  }
}

.stucco__caption {
  color: $white;
}

.more-slider {
  @media $small-handheld {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }

  .slick-track {
    display: flex;
    gap: 30px;
    min-width: 0;

    &::before,
    &::after {
      content: none;
    }
  }
}

.more-slider__item {
  display: inline-flex !important;
  flex-direction: column;
  gap: 15px;
  text-decoration: none;
}

.more-slider__item-name {
  font-size: 2.4rem;
  font-weight: $medium;
  color: $white;

  @media $small-notebook-and-less {
    font-size: 2rem;
  }

  @media $small-handheld {
    font-size: 1.8rem;
  }

  @media $small-mobile {
    font-size: 1.6rem;
  }
}

.more-slider__controls {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;
  margin-top: 50px;

  @media $handheld {
    margin-top: 30px;
  }

  .slick-dots {
    margin-top: -10px;
    width: auto;
    flex: 1;
  }
}

.more-slider__arrows {
  display: flex;
  align-items: center;
  gap: 20px;
}