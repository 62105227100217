@import "../_common_includes";
@import "../components/_more-slider";
@import "../components/_delivery";

@critical homepage-critical.css {
  .homepage-wall {
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      bottom: -310px; left: 0;
      display: block;
      width: 100%; height: height('i/wave-effect.svg');
      background: url('/i/wave-effect.svg') 0 no-repeat;
      pointer-events: none;

      @media $notebook-and-less {
        bottom: -280px;
      }

      @media only screen and (max-width: 1280px) {
        bottom: -195px;
        height: height('i/wave-effect-notebook.svg');
        background: url('/i/wave-effect-notebook.svg') 0 / cover no-repeat;
      }
    }

    &::before {
      content: '';
      position: absolute;
      right: 0; bottom: 20px;
      z-index: 1;
      display: block;
      width: width('i/small-handheld-wall-decor.png');
      height: height('i/small-handheld-wall-decor.png');
      background: url('/i/small-handheld-wall-decor.png') 0 no-repeat;

      @media $not-small-handheld {
        content: none;
      }
    }
  }

  .homepage-wall__slider-controls {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: baseline;
    flex-direction: row-reverse;
    gap: 20px;
    z-index: 1;

    @media $notebook-and-less {
      bottom: 28%;
    }

    @media only screen and (max-width: 1280px) {
      bottom: 15px;
      z-index: 2;

      .slick-arrow {
        filter: brightness(0);
      }
    }

    @media $handheld {
      bottom: 150px;
    }

    .arrows {
      display: flex;
      align-items: center;
      gap: 20px;

      @media $handheld {
        display: none;
      }
    }
  }

  .homepage-wall__slider-content {
    position: absolute;
    left: 50%; top: 50px;
    transform: translateX(-50%);
    text-align: center;
    letter-spacing: 0.03em;
    pointer-events: none;

    @media only screen and (max-width: 1280px) {
      top: 25px;
    }

    @media $handheld {
      top: 7vw;
    }
  }

  .homepage-wall__caption {
    font-size: 6rem;
    color: $white;
    font-weight: $heavy;
    font-family: $caption-font;
    position: relative;

    @media $notebook-and-less {
      font-size: 4.5rem;
    }

    &::before {
      content: '';
      position: absolute;
      left: -30px; top: -30px;
      display: block;
      width: width('i/homepage-caption-decor.png');
      height: height('i/homepage-caption-decor.png');
      background: url('/i/homepage-caption-decor.png') 50% no-repeat;

      @media $notebook-and-less {
        width: 80px; height: @width;
        background-size: contain;
      }

      @media $handheld {
        content: none;
      }
    }

    span {
      color: $turq;
    }
  }

  .homepage-wall__remark {
    font-size: 1.2rem;
    color: $text-gray;
    margin: 10px 0 20px;
  }

  .homepage-wall__link {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-size: 1.4rem;
    color: $turq;
    text-decoration: none;

    &::after {
      content: '';
      display: inline-block;
      width: 8px; height: @width;
      transform: rotate(45deg);
      border-top: 1px solid $turq;
      border-right: 1px solid $turq;
    }
  }

  .homepage-wall__slider {
    display: flex;
    overflow: hidden;
    position: relative;

    .slick-slide {
      position: relative;
    }
  }

  .homepage-wall__slide-item {
    display: block !important;
    position: relative;
    flex-basis: 100vw;
    flex-shrink: 0;
    padding-bottom: 23.66%;
    overflow: hidden;

    @media $notebook-and-less {
      padding-bottom: 27%;
    }

    @media $handheld {
      padding-bottom: 55%;
    }

    @media $small-mobile {
      padding-bottom: 138.3%;
    }
  }

  .homepage-wall__bg {
    width: 100%;
    position: absolute;
    left: 0; top: 0;
  }

  .homepage-wall__action {
    display: block;
    text-decoration: none;
    background: $turq;
    padding: 20px 45px;
    position: absolute;
    right: 0; top: 37px;
    z-index: 100;
    padding-right: 18vw;

    @media $small-handheld {
      padding: 15px 20px;
      right: 20px; top: 15px;
    }
  }

  .homepage-wall__action-text {
    width: 300px;
    font-family: $caption-font;
    font-size: 2rem;

    @media $handheld {
      width: 240px;
      font-size: 1.6rem;
    }

    @media $small-handheld {
      font-size: 1.3rem;
    }

    @media $small-mobile {
      width: 174px;
    }
  }

  .homepage-wall__action-more-icon {
    btn();
    width: 93px; height: @width;
    border-radius: 50%;
    border: 1px solid $black;
    background: url(/i/arrow-right.svg) no-repeat 50% / 43px auto;
    position: absolute;
    left: 345px; bottom: 0;
    transform: translateY(60%);

    @media $small-handheld {
      display: none;
    }
  }

  .homepage-wall__action:hover .homepage-wall__action-more-icon {
    background-color: $dark-turq;
    border-color: $dark-turq;
  }

  .about-shop {
    position: relative;
    margin-top: -20px;
    background: $white url('/i/shop-features-bg.png') 100% 50% no-repeat;
    z-index: 1;

    @media $small-handheld {
      background: $white url('/i/mobile-caption-decor.svg') 0 0 no-repeat;
      background-size: 50%;
    }
  }

  .about-shop__inner {
    max-width: 1135px;
    padding: 30px 0 80px $desktop-paddings;
    position: relative;

    @media $notebook-and-less {
      max-width: 65vw;
    }

    @media $small-notebook-and-less {
      padding-left: $tablet-paddings;
      padding-top: 45px;
    }

    @media $small-handheld {
      max-width: 90vw;
      padding-bottom: 30px;
    }
  }

  .homepage-caption {
    margin-bottom: 15px !important;
  }

  .about-shop__content {
    font-size: 2rem;
    line-height: 1.6;
    color: rgba($asphalt, 0.8);

    @media only screen and (max-width: 1280px) {
      font-size: 1.6rem;
    }
  }
}

.shop-features {
  position: relative;
  padding-top: 110px;
  padding-bottom: 70px;
  background: url('/i/features-bg.png') 100% -145px no-repeat;

  @media $small-handheld {
    background: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 20px; left: $desktop-paddings;
    z-index: 1;
    display: block;
    width: 'calc(100vw - 2 * %s)' % $desktop-paddings;
    height: 12px;
    background: url('/i/border-decor.svg') 50% no-repeat;

    @media $notebook-and-less {
      background-size: contain;
    }

    @media $small-notebook-and-less {
      left: $tablet-paddings;
      width: 'calc(100vw - 2 * %s)' % $tablet-paddings;
    }

    @media $small-handheld {
      height: height('i/border-decor-mobile.svg');
      background-image: url('/i/border-decor-mobile.svg');
    }
  }
}

.shop-features__inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  @media $handheld {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    margin-right: - $tablet-paddings;
  }
}

.shop-features__item {
  text-align: center;

  @media $handheld {
    min-width: 35vw;
    scroll-snap-align: start;
  }
}

.shop-features__item-title {
  font-size: 2.4rem;
  font-weight: $medium;
  margin-bottom: 15px;

  @media $small-handheld {
    font-size: 2rem;
  }
}

.shop-features__item-content {
  position: relative;
  font-size: 1.4rem;
  color: rgba($asphalt, 0.8);
  line-height: 1.4;
  padding: 0 30px;
  padding-top: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0; left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 115px;
    border-bottom: 1.5px solid $yellow;
  }
}

.popular-categories {
  background-color: $asphalt;
  padding: 70px 0;
}

.popular-categories__inner {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 65px 35px;

  @media $notebook-and-less {
    gap: 35px 20px;
  }
}

.popular-categories__item {
  display: block;
  text-decoration: none;

  &:hover {
    .popular-categories__item-img img {
      transform: none;
    }

    .popular-categories__item-name {
      color: $turq;
    }
  }
}

.popular-categories__item:nth-child(1) {
  grid-row: 1 / 4;
  grid-column: 1 / 2;
}

.popular-categories__item:nth-child(2) {
  grid-column: 2 / 4;
  grid-row: 1 / 3;
}

.popular-categories__item:nth-child(3) {
  grid-column: 4 / 5;
  grid-row: 1 / 7;
}

.popular-categories__item:nth-child(4) {
  grid-column: 1 / 2;
  grid-row: 4 / 7;
}

.popular-categories__item:nth-child(5) {
  grid-column: 2 / 3;
  grid-row: 3 / 5;
}

.popular-categories__item:nth-child(6) {
  grid-column: 3 / 4;
  grid-row: 3 / 7;
}

.popular-categories__item:nth-child(7) {
  grid-column: 2 / 3;
  grid-row: 5 / 7;
}

.popular-categories__item-img {
  width: 100%;
  height: calc(100% - 35px);
  margin-bottom: 15px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 1s ease;
    transform: scale(1.05);
  }
}

.popular-categories__item-name {
  color: $white;
  font-size: 2.8rem;
  transition: all 1s ease;

  @media $notebook-and-less {
    font-size: 2.2rem;
  }

  @media $handheld {
    font-size: 1.8rem;
  }
}

.showcase {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: $night;

  @media only screen and (max-width: 1280px) {
    padding-top: 50px;
  }
}

.about-company {
  padding: 95px 0 350px;
  background: url('/i/about-company-bg.png') 100% -300px no-repeat;

  @media $small-handheld {
    background-size: 30%;
    background-position-y: -40px;
    padding: 50px 0;
  }

  & + div,
  & + section {
    @media $not-small-handheld {
      margin-top: -250px;
    }
  }
}

.about-company__content {
  font-size: 1.8rem;
  line-height: 1.55;
  color: rgba($asphalt, 0.75);
  max-width: 990px;

  @media $small-notebook-and-less {
    max-width: 60vw;
  }

  @media $handheld {
    font-size: 1.6rem;
  }
}

.about-company__caption {
  margin-bottom: 60px;
  color: $asphalt;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0; bottom: -30px;
    display: block;
    width: width('i/about-company-decor.svg');
    height: height('i/about-company-decor.svg');
    background: url('/i/about-company-decor.svg') 50% no-repeat;
  }
}

.popular {
  padding: 50px 0 90px;
}

.popular__inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media $small-handheld {
    grid-template-columns: repeat(4, 45vw);
    overflow-x: auto;
  }
}

.popular__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  box-sizing: border-box;
  position: relative;
  padding: 0 20px;

  @media $handheld {
    height: 200px;

    img {
      max-height: 85%;
    }
  }

  &:not(:nth-child(4n)) {
    border-right: 1px solid rgba($asphalt, 40%);

    &:not(:nth-last-child(-n + 4))::after {
      content: '';
      position: absolute;
      left: calc(100% - 7px); bottom: -22px;
      display: block;
      height: 44px; width: 14px;
      z-index: 1;
      background: url('/i/border-mid-decor.svg') 50% no-repeat;
    }
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    &::before {
      content: '';
      position: absolute;
      left: -6px; top: 0;
      display: block;
      height: 30px; width: 12px;
      background: url('/i/border-top-decor.svg') 50% no-repeat;
    }
  }

  &:nth-last-child(-n+3) {
    &::before {
      content: '';
      position: absolute;
      left: -6px; bottom: 0;
      display: block;
      height: 30px; width: 12px;
      background: url('/i/border-top-decor.svg') 50% no-repeat;
      transform: scaleY(-1);
    }
  }

  &:not(:nth-last-child(-n + 4)) {
    border-bottom: 1px solid rgba($asphalt, 40%);
  }

  &:nth-child(4n+1):not(:nth-last-child(-n + 4)) {
    &::before {
      content: '';
      position: absolute;
      left: 0; bottom: -6px;
      display: block;
      width: 30px; height: 12px;
      background: url('/i/border-left-decor.svg') 50% no-repeat;
    }
  }
}

.homepage-offer {
  padding: 150px 0;
  position: relative;
  background: url('/i/home-offer-left-decor.png') 0 -25px no-repeat, url('/i/home-offer-right-decor.png') 100% 100% no-repeat;

  @media only screen and (max-width: 1280px) {
    background: url('/i/home-offer-left-decor.png') 0 -25px no-repeat, url('/i/home-offer-right-decor.png') 150% 100% no-repeat;
  }

  @media $handheld {
    background: url('/i/home-offer-left-decor.png') 0 -25px no-repeat, url('/i/home-offer-right-decor.png') 200% 100% no-repeat;
  }

  @media $small-handheld {
    background: url('/i/mobile-caption-decor.svg') 0 0 no-repeat, url('/i/home-offer-mobile.png') 100% 0 no-repeat;
    padding: 10px 0 100px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 20px; left: $desktop-paddings;
    z-index: 1;
    display: block;
    width: 'calc(100vw - 2 * %s)' % $desktop-paddings;
    height: 12px;
    background: url('/i/border-decor.svg') 50% no-repeat;

    @media $notebook-and-less {
      background-size: contain;
    }

    @media $small-notebook-and-less {
      left: $tablet-paddings;
      width: 'calc(100vw - 2 * %s)' % $tablet-paddings;
    }

    @media $small-handheld {
      height: height('i/border-decor-mobile.svg');
      background-image: url('/i/border-decor-mobile.svg');
    }
  }
}

.homepage-offer__content {
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: rgba($asphalt, 75%);
  margin-bottom: 40px;
  max-width: 777px;

  @media $notebook-and-less {
    max-width: 40vw;
  }

  @media $handheld {
    font-size: 1.6rem;
  }

  @media $small-handheld {
    max-width: 100%;
  }
}

.homepage-offer__btn {
  padding: 16px 85px;
  color: $asphalt;
  font-size: 1.6rem;
  font-weight: $bold;
  text-decoration: none;
  border: 1px solid $asphalt;
  display: inline-block;
  box-sizing: border-box;

  &:hover {
    background-color: $asphalt;
    color: $white;
  }
}
.site-content {
  margin-top: -8vw;
  overflow: hidden;
}

.vitrine__product-image .product__image {
  max-height: 270px;
  min-height: 270px;
}

.homepage-wall__slide-item {
  padding-bottom: 0;
}

.slick-slide img.homepage-wall__bg {
  display: block;
  height: 31vw;
  position: relative;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.ft-wr .shop-features__item {
  width: calc(((100vw - 200px) / 4)) !important;
}

.ft-wr .slick-slide img {
  height: auto;
}

.ft-wr .slick-arrow {
  opacity: 0.7;
  filter: brightness(0.2);
}

.homepage-wall__slider-content {
  top: 55%;
  transform: translate(-50%, -50%);
}

.homepage-wall__caption::before {
  display: none;
}

.popp-wr .popular__inner {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  width: calc(50vw - 100px) !important;
  max-width: 100%;
}

.popular-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 300px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  padding: 0 20px;
}

.popular-item:not(:nth-last-child(-n+2)) {
  border-bottom: 1px solid rgba(30, 30, 30, 0.4);
}

.popular-item:not(:nth-child(2n)) {
  border-right: 1px solid rgba(30, 30, 30, 0.4);
}

.popular-item:nth-child(2)::before {
  content: '';
  position: absolute;
  left: -8px;
  top: -1px;
  display: block;
  height: 33px;
  width: 15px;
  background: url('/i/hd/border-top-decor.svg') 0 0 no-repeat;
  background-size: 100% 100%;
}

.popular-item:nth-child(3)::before {
  content: '';
  position: absolute;
  right: -8px;
  bottom: -1px;
  display: block;
  height: 33px;
  width: 15px;
  background: url('/i/hd/border-top-decor.svg') 0 0 no-repeat;
  background-size: 100% 100%;
  transform: scaleY(-1);
}

.popular-item:nth-child(1)::after {
  content: '';
  position: absolute;
  right: -8px;
  bottom: -23px;
  display: block;
  height: 45px;
  width: 15px;
  z-index: 1;
  background: url('/i/hd/border-mid-decor.svg') 0 0 no-repeat;
  background-size: 100% 100%;
}

.popp-wr .slick-slide:first-child .popular-item:nth-child(1)::before {
  content: '';
  position: absolute;
  left: -1px;
  bottom: -8px;
  display: block;
  width: 33px;
  height: 15px;
  background: url('/i/hd/border-left-decor.svg') 0 0 no-repeat;
  background-size: 100% 100%;
}

.popp-wr .slick-slide:last-child .popular-item:nth-child(2)::after {
  content: '';
  position: absolute;
  right: -1px;
  bottom: -8px;
  display: block;
  width: 33px;
  height: 15px;
  background: url('/i/hd/border-left-decor.svg') 0 0 no-repeat;
  background-size: 100% 100%;
  transform: scaleX(-1);
}

@media only screen and (min-width: 1921px) {
  .about-shop__inner {
    padding-left: 0;
  }

  .shop-features::before {
    width: 1720px;
  }

  .ft-wr .shop-features__item {
    width: 430px !important;
  }

  .homepage-offer::after {
    width: 1720px;
  }
}

@media only screen and (min-width: 1025px) {
  .popp-wr .slick-slide:not(:last-child) .popular-item:nth-child(2)::after {
    content: '';
    position: absolute;
    right: -8px;
    bottom: -23px;
    display: block;
    height: 45px;
    width: 15px;
    z-index: 1;
    background: url('/i/hd/border-mid-decor.svg') 0 0 no-repeat;
    background-size: 100% 100%;
  }

  .popp-wr .slick-slide:not(:last-child) .popular-item:nth-child(2n) {
    border-right: 1px solid rgba(30, 30, 30, 0.4);
  }

  .popp-wr .slick-slide:not(:first-child) .popular-item:nth-child(1)::before {
    content: '';
    position: absolute;
    left: -8px;
    top: -1px;
    display: block;
    height: 33px;
    width: 15px;
    background: url('/i/hd/border-top-decor.svg') 0 0 no-repeat;
    background-size: 100% 100%;
  }

  .popp-wr .slick-slide:first-child .popular-item:nth-child(4)::before {
    content: '';
    position: absolute;
    right: -8px;
    bottom: -1px;
    display: block;
    height: 33px;
    width: 15px;
    background: url('/i/hd/border-top-decor.svg') 0 0 no-repeat;
    background-size: 100% 100%;
    transform: scaleY(-1);
  }
}

@media only screen and (max-width: 1580px) {
  .homepage-wall__slider-controls {
    bottom: 5vw;
    width: 25vw;
  }

  .ft-wr .shop-features__item {
    width: calc(((100vw - 200px) / 3)) !important;
  }
}

@media (max-width: 1480px) {
  .site-content {
    margin-top: -9vw;
  }

  .homepage-wall .slick-slide img {
    height: 39vw;
  }

  .homepage-wall__slider-controls {
    bottom: 7vw;
    width: 25vw;
  }
}

@media (max-width: 1366px) {
  .ft-wr .shop-features__item {
    width: calc(((100vw - 100px) / 3)) !important;
  }
}

@media (max-width: 1280px) {
  .ft-wr .shop-features__item {
    width: calc(((100vw - 40px) / 3)) !important;
  }

  .homepage-wall__slider-controls .slick-arrow {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }

  .homepage-wall__caption {
    font-size: 3.5rem;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1480px) {
  .popp-wr .popular__inner {
    width: calc(50vw - 50px) !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .popp-wr .popular__inner {
    width: calc(50vw - 20px) !important;
  }
}

@media only screen and (min-width: 897px) and (max-width: 1024px) {
  .about-company + div,
  .about-company + section {
    margin-top: 0;
  }
}

@media (max-width: 1024px) {
  .ft-wr .shop-features__item {
    width: calc(((100vw - 40px) / 2)) !important;
  }

  .shop-features__item-content {
    font-size: 0;
  }

  .shop-features__item-title {
    color: var(--Night-Asphalt, #1E1E1E);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 5px;
  }

  .shop-features__item-content::before {
    width: 22vw;
  }

  .ft-wr .more-slider__controls {
    margin-top: 0;
  }

  .shop-features__inner {
    width: 100%;
  }

  .site-content {
    margin-top: -120px;
  }

  .homepage-wall__slider-content {
    width: 267px;
    top: 50%;
  }

  .homepage-wall__caption {
    font-size: 3rem;
  }

  .homepage-wall .slick-slide img {
    height: 130vw;
  }

  .homepage-wall__slider-controls {
    bottom: 33vw;
    width: 60vw;
    justify-content: center;
  }

  .about-shop {
    background-size: 75%;
  }

  .about-shop__inner {
    padding-top: 10px;
    max-width: 100%;
  }

  .homepage-caption {
    color: var(--Night-Asphalt, #1E1E1E);
    font-family: 'Fira Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.24px;
  }

  .about-shop__content {
    color: rgba(30, 30, 30, 0.8);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-right: 20px;
  }

  .shop-features::before {
    height: 22px;
    background-image: url('/i/hd/home_del_mb.svg');
    width: 100%;
    left: auto;
    top: 0;
    width: calc(100vw - 40px);
  }

  .shop-features {
    padding-top: 50px;
    text-align: center;
  }

  .popular-categories {
    padding-top: 40px;
    padding-bottom: 40px;
    background: url('/i/hd/home_2_mb.webp') no-repeat 100% calc(100% + 150px), #1e1e1e;
  }

  .popular-categories__caption {
    text-align: center;
  }

  .popular-categories__inner {
    width: 100vw;
    margin-left: -20px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .popular-categories__item:nth-child(1) {
    width: 53%;
  }

  .popular-categories__item:nth-child(2) {
    width: calc(47% - 15px);
  }

  .popular-categories__item:nth-child(3) {
    width: calc(47% - 15px);
  }

  .popular-categories__item:nth-child(4) {
    width: 53%;
  }

  .popular-categories__item:nth-child(5) {
    width: 53%;
  }

  .popular-categories__item:nth-child(6) {
    width: calc(47% - 15px);
  }

  .popular-categories__item:nth-child(7) {
    width: calc(47% - 15px);
  }

  .popular-categories__item:nth-child(8) {
    width: 53%;
  }

  .popular-categories__item-img {
    height: 53vw;
  }

  .popular-categories__item-name {
    color: var(--Clear-White, var(--Clear-White, #FFF));
    font-family: 'Fira Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }

  .vitrine--catalogue {
    grid-column-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  .product-icons__item {
    line-height: 24px;
    padding: 0 8px;
    white-space: nowrap;
  }

  .vitrine.vitrine--mobile-scroller .vitrine__product-footer {
    position: static;
    justify-content: space-between;
  }

  .catalogue-page__content .vitrine__product-image .product__image {
    height: 30vw;
    max-height: 30vw;
    min-height: 30vw;
  }

  .catalogue-page__content .vitrine__product-description {
    font-size: 1.2rem;
    color: #fff;
    margin-top: 5px;
    line-height: 1;
  }

  .catalogue-page__content .vitrine__product-article {
    font-size: 1.1rem;
  }

  .catalogue-page__content .vitrine__product-footer {
    flex-direction: column;
    align-items: start;
    position: static;
  }

  .vitrine.vitrine--mobile-scroller .vitrine__product-buy-button {
    width: auto;
    padding: 0 50px;
    margin: 0;
    height: 50px;
    line-height: 45px;
    margin-bottom: 2px;
  }

  .vitrine__product-favorite {
    position: absolute;
    top: 10px;
    right: 8px !important;
    background: rgba(48, 48, 48, 0.5);
    backdrop-filter: blur(20px);
    filter: none;
    width: 24px;
    height: 24px;
  }

  .vitrine__product-compare {
    position: absolute;
    top: 40px !important;
    right: 8px !important;
    background: rgba(48, 48, 48, 0.5);
    backdrop-filter: blur(20px);
    filter: none;
    margin: 0;
    width: 24px;
    height: 24px;
  }

  .vitrine__product-favorite svg,
  .vitrine__product-compare svg {
    max-width: 100%;
    max-height: 100%;
  }

  .vitrine-section__caption {
    font-size: 24px;
  }

  .catts-seen .vitrine .slick-slide .vitrine__product {
    min-width: calc(100vw - 42px);
    max-width: calc(100vw - 42px);
  }

  .catalogue-page__content .vitrine__product-price-wrapper {
    width: 100%;
    align-items: start;
  }

  .catalogue-page__content .vitrine__product-price {
    font-size: 1.6rem;
  }

  .catalogue-page__content .vitrine__product-amount {
    transform: translateY(0);
    position: static;
  }

  .catalogue-page__content .vitrine__product-buy-button {
    width: 100%;
    margin-top: 20px;
    font-size: 1.6rem;
    line-height: 40px;
  }

  .tabs--default .tabs-nav {
    width: 100vw;
    margin-left: -20px;
  }

  .tabs--default .tabs-nav a {
    color: var(--Clear-White, var(--Clear-White, #FFF));
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    padding: 0 0 10px;
    white-space: nowrap;
    width: 100%;
  }

  .tabs--default .tabs-content {
    margin-top: 40px !important;
  }

  .tabs--default .tabs-nav li {
    flex-grow: 1;
  }

  .vitrine-more-link {
    width: 100%;
    max-width: 500px;
  }

  .about-company__content {
    font-size: 14px;
  }

  .about-company {
    padding-bottom: 0;
    background-size: 60%;
    background-position-y: 60px;
  }

  .popp-wr .popular__inner {
    width: calc(100vw - 40px) !important;
    overflow: visible;
  }

  .popp-wr .caption {
    text-align: center;
  }

  .popular-item {
    height: 150px;
  }

  .popular-item img {
    max-width: 80%;
    max-height: 90%;
    height: auto;
  }

  .popp-wr .slick-slide:first-child .popular-item:nth-child(2)::after {
    content: '';
    position: absolute;
    right: -1px;
    bottom: -8px;
    display: block;
    width: 33px;
    height: 15px;
    background: url('/i/hd/border-left-decor.svg') 0 0 no-repeat;
    background-size: auto;
    background-size: 100% 100%;
    transform: scaleX(-1);
  }

  .popp-wr .slick-slide:not(:first-child) .popular-item:nth-child(1)::before {
    content: '';
    position: absolute;
    left: -1px;
    bottom: -8px;
    display: block;
    width: 33px;
    height: 15px;
    background: url('/i/hd/border-left-decor.svg') 0 0 no-repeat;
    background-size: auto;
    background-size: 100% 100%;
  }

  .popp-wr .more-slider__arrows {
    display: none;
  }

  .popp-wr {
    padding-bottom: 40px;
  }

  .stucco {
    padding-top: 40px;
    text-align: center;
  }

  .more-slider {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 24px;
    column-gap: 16px;
  }

  .reviews-list .slick-list {
    margin-right: -20px;
  }

  .homepage .reviews-section {
    padding-bottom: 60px;
  }

  .reviews-list .slick-dots {
    margin-top: 20px;
  }

  .reviews-section__more-link {
    margin-top: 30px;
  }

  .homepage .reviews-section::after {
    content: '';
    display: block;
    width: 100%;
    height: 29px;
    position: absolute;
    bottom: -1px;
    left: 0;
    background: url('/i/wave_effect-reviews.svg') 50% no-repeat;
    background-size: auto;
    background-size: 150vw auto;
  }

  .homepage-offer {
    padding: 50px 0;
    background: url('/i/mobile-caption-decor.svg') 0 30px no-repeat, url('/i/hd/home_3_mb.webp') 100% 10px no-repeat;
  }

  .homepage-offer__content {
    color: rgba(30, 30, 30, 0.75);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .homepage-offer__btn {
    width: 100%;
    text-align: center;
  }

  .homepage-offer::after {
    height: 22px;
    background-image: url('/i/hd/home_del_mb.svg');
    width: 100%;
    left: 20px;
    bottom: 0;
    width: calc(100vw - 40px);
    margin: auto;
  }

  .homepage-delivery.delivery {
    margin: 32px 0;
  }

  .footer {
    margin-top: -60px;
  }

  .delivery__inner {
    grid-template-columns: 1fr;
    border: none;
  }

  .delivery__zones {
    padding: 0 0 32px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0;
    row-gap: 30px;
  }

  .delivery__zones-item {
    max-width: 47%;
    min-width: 47%;
  }

  .delivery__zones-item-price {
    min-width: 100%;
    color: rgba(25, 25, 25, 0.7);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .delivery__map {
    height: 110vw;
  }

  .delivery__zones-item-name {
    color: #191919;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .delivery__zones-item-region {
    color: rgba(25, 25, 25, 0.7);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    height: 50px;
  }
}

@media (max-width: 360px) {
  .tabs--default .tabs-nav a {
    font-size: 14px;
  }

  .delivery__zones-item-region {
    height: 70px;
  }
}