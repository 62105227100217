.delivery {
  margin: 70px 0;
}

.delivery__inner {
  display: grid;
  grid-template-columns: 25% 1fr;
  border: 1px solid #bababa;

  @media only screen and (max-width: 1280px) {
    grid-template-columns: 35% 1fr;
  }

  @media $small-handheld {
    grid-template-columns: 45% 1fr;
  }
}

.delivery__zones {
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media $small-handheld {
    padding: 15px;
  }
}

.delivery__zones-item-name {
  font-size: 2.4rem;
  font-weight: $bold;
  margin-bottom: 10px;

  @media $handheld {
    font-size: 2rem;
  }
}

.delivery__zones-item-region {
  font-size: 1.8rem;
  font-weight: $extra-bold;
  margin-bottom: 5px;
  color: rgba(#191919, 0.7);

  @media $handheld {
    font-size: 1.4rem;
  }
}

.delivery__zones-item-price {
  min-width: 220px;
  display: inline-block;
  border-bottom: 4px solid;
  padding-bottom: 10px;
  font-size: 1.8rem;
  font-weight: $extra-bold;
  color: rgba(#191919, 0.7);

  @media $handheld {
    font-size: 1.4rem;
  }
}

.delivery__map {
  .map {
    display: block;
    width: 100%;
    height: 100%;
  }
}